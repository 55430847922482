import { useQuery } from '@tanstack/react-query'
import { getUser, getUserEvents } from 'api/user'
import { STALE_TIME, serializeQueryKey } from 'helpers/query'
import { useCallback } from 'react'
import { create, emptyUser } from 'schemas/user'

function getQuerykey(key, id, query) {
  return serializeQueryKey(key, {
    id,
    ...query,
  })
}

export function getUserQueryKey(id, query = {}) {
  return getQuerykey('user', id, query)
}

export function getUserEventsQueryKey(id, query = {}) {
  return getQuerykey('user.events', id, query)
}

export function useUserQuery(id, query, { select: transformData, ...reactQueryOptions } = {}) {
  return useQuery({
    placeholderData: emptyUser,
    staleTime: STALE_TIME,
    queryKey: getUserQueryKey(id, query),
    queryFn: () => getUser(id, query),
    select: useCallback(
      (raw) => {
        const data = create(raw)
        return transformData ? transformData(data) : data
      },
      [transformData],
    ),
    ...reactQueryOptions,
  })
}

export function useUserEventsQuery(id, query, ...reactQueryOptions) {
  return useQuery({
    placeholderData: [],
    staleTime: STALE_TIME,
    queryKey: getUserEventsQueryKey(id, query),
    queryFn: () => getUserEvents(id, query),
    ...reactQueryOptions,
  })
}
